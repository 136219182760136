import React from 'react';

import styles from './card.module.scss';

class Card extends React.Component {
    handleClick = () => {
        const { linkUrl, onClick, name } = this.props;

        onClick(name);

        if (window) {
            window.open(linkUrl, "_blank");
        }
    };

    render() {
        const { iconSrc, iconAlt, title, description } = this.props;

        return (
            <div className={styles.cardContainer} onClick={this.handleClick}>
                <div className={styles.innerContainer}>
                    <div className={styles.icon}>
                        <img src={iconSrc} width={48} height={48} />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            {title}
                        </div>
                        <div className={styles.description}>
                            {description}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Card;
