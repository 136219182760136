import React from 'react';
import Helmet from 'react-helmet';
import { withMixpanel } from 'gatsby-plugin-mixpanel'

import CONTENT from '../data/content';

import Card from '../components/Card';
import CategoryCard from '../components/CategoryCard';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

import styles from './index.module.scss';

class Index extends React.Component {
    handleClick = (name) => {
        const {mixpanel} = this.props;

        const event = 'resource_center_click';

        mixpanel.track(event, {link: name});
    };

    render() {
        return (
            <React.Fragment>
                <Helmet title="Perspective Resources" defer={false} />
                <div className={styles.page}>
                    {/*    Navigation */}
                    <NavigationBar lang='de' />
                    {/*    Content */}
                    {/*    Headline */}
                    <div className={styles.headline}>
                        <span>{CONTENT.headline['de']}</span>
                    </div>
                    {/*    Main */}
                    <div className={styles.main}>
                        <div className={styles.mainCards}>
                            {CONTENT.mainCards.map(card =>
                                <CategoryCard key={card.name}
                                    name={card.name}
                                    imgSrc={card.imgSrc}
                                    title={card.title['de']}
                                    description={card.description['de']}
                                    linkUrl={card.url}
                                    onClick={this.handleClick}/>
                            )}
                        </div>
                        {/*    Sub */}
                        <div className={styles.mainSub}>
                            {CONTENT.categories.map(category =>
                                <div className={styles.category} key={category.title['de']}>
                                    <div className={styles.title}>{category.title['de']}</div>
                                    <div className={styles.links}>
                                        {category.subCards.map(subCard =>
                                            <Card key={subCard.name}
                                              name={subCard.name}
                                              iconSrc={subCard.iconSrc}
                                              title={subCard.title['de']}
                                              description={subCard.description['de']}
                                              linkUrl={subCard.url}
                                              onClick={this.handleClick} />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Footer lang={'de'} />
                </div>
            </React.Fragment>
        )
    }
}

export default withMixpanel()(Index)
